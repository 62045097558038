
// En awardspace
// export const API_URL = 'https://leonsayas.com/backend/';

// En awardspace financedemo
// export const API_URL = 'https://financedemo.visssible.com/backend';

// En dreamhost
// export const API_URL = 'https://jobsay.dreamhosters.com/financeBackend/';

// En hostinger Areli
// export const API_URL = 'https://lawngreen-oyster-299671.hostingersite.com/financeBackend';

// En hostinger Vissible Finance
// export const API_URL = 'https://finance.visssible.com/backend';

// En hostinger Vissible Demo
export const API_URL = 'https://financedemo.visssible.com/backend';